@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-dark: #200436
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #200436;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
  background-image: url(./assets//background.png),
  url(./assets//background-bottom.png);
  background-position:
  top left,
  bottom right;
  background-repeat:no-repeat;
  background-attachment:fixed;
}
.chat-bg {
  background-image: url(./assets//bg-top-right.png);
  background-position:
  top right;
  background-repeat:no-repeat;
  background-attachment:fixed;
  display: flex;
}
.section {
    background-image: url(./assets//sectionBackground.png);
    background-position:
    center;
    background-repeat:no-repeat;
    height: 863px;
    width: 100%;
    position: absolute;
    top: -400px;
    left: 0;
}
.terms-bg {
  background-image: url(./assets//sectionBackground.png);
  background-position:
  center;
  background-repeat:no-repeat;
  height: 100%;
}
.gradientCard {
  background: linear-gradient(90deg, #E5AE2166 0%, #411B5D 50%, #411B5D 100%);
  background-color: #411B5D;
}
.gradientCardReverse {
  background: linear-gradient(90deg, #411B5D 0%, #411B5D 50%, #E5AE2166 100%);
  background-color: #411B5D;
}
.gradientPrice {
  background: linear-gradient(180deg, #411B5D 0%, #411B5D 50%, #E5AE2166 100%);
  background-color: #411B5D;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media screen and (max-width: 768px) {
 .background {
  background-image: none;
 }
  .mobile-chat {
    max-height: calc(100vh - 152px);
  }
}

.custom-table{
    tr{
        td{
        border: 1px solid #fff;
        }

    }
}